import { Component, FunctionComponent, ReactNode } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Color, Storage } from "../../env";
import {
    IGetDelivery,
    Delivery as DeliveryDto,
    UpdateDeliveryRadius,
    UpdateDeliveryPrice,
    DeliveryDate as DeliveryDateDto,
    Day,
    UpdateDeliveryDate,
    UpdateMinimumPrice,
    IDeliveryOptIn,
    IDeliveryOptOut
} from "../../client/core";
import DeliveryRadius from "./DeliveryRadius";
import DeliveryPrice from "./DeliveryPrice";
import { Alert, Card, Col, Row } from "react-bootstrap";
import DeliveryDate from "./DeliveryDate";
import MinimumPrice from "./MinimumPrice";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

export interface DeliveryState {
    delivery?: DeliveryDto;
    error: string;
}

export interface DeliveryProps extends RouteComponentProps {
    auth: typeof Auth;
    getDeliveryAPI: IGetDelivery;
    optInDeliveryAPI: IDeliveryOptIn;
    optOutDeliveryAPI: IDeliveryOptOut;
}

export default class Delivery extends Component<DeliveryProps, DeliveryState> {
    constructor(props: DeliveryProps) {
        super(props);
        this.state = {
            error: "",
        };
    }

    updateRadius = (value: number): void => {
        const { delivery } = this.state;
        if (!delivery) {
            return;
        }
        delivery!.radius = value;
        this.setState({
            delivery: delivery,
        });
    };

    optInForIntegratedDelivery = (): void => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            return
        }

        this.props.auth.currentSession().then(session => {
            this.props.optInDeliveryAPI.DeliveryOptIn({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID
            }).then(response => {
                if (response.statusCode === 403) {
                    localStorage.removeItem(Storage.ShopID)
                    return this.setState({
                        error: "You are forbidden to update delivery preference. Please navigate back to the shop home dashboard to resync."
                    })
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Failed to opt for integrated delivery services which might imply this feature is not currently available in your area. If you know a local delivery company you can refer them to us and we'll aim to get them on-boarded.",
                    })
                }

                this.setState({
                    delivery: {
                        ...this.state.delivery!,
                        isIntegrated: true,
                    }
                })
            })
        })
    }

    optOutFromIntegratedDelivery = (): void => {
        const shopID = localStorage.getItem(Storage.ShopID)
        if (!shopID) {
            return
        }

        this.props.auth.currentSession().then(session => {
            this.props.optOutDeliveryAPI.DeliveryOptOut({
                identityToken: session.getIdToken().getJwtToken(),
                shopID: shopID
            }).then(response => {
                if (response.statusCode === 403) {
                    localStorage.removeItem(Storage.ShopID)
                    return this.setState({
                        error: "You are forbidden to update delivery preference. Please navigate back to the shop home dashboard to resync."
                    })
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Failed to opt for integrated delivery services which might imply this feature is not currently available in your area. If you know a local delivery company you can refer them to us and we'll aim to get them on-boarded.",
                    })
                }

                this.setState({
                    delivery: {
                        ...this.state.delivery!,
                        isIntegrated: false,
                    }
                })
            })
        })
    }

    componentDidMount() {
        this.props.auth
            .currentSession()
            .then((session) => {
                this.props.getDeliveryAPI
                    .GetDelivery({
                        identityToken: session.getIdToken().getJwtToken(),
                        shopID: localStorage.getItem(Storage.ShopID)!,
                    })
                    .then((response) => {
                        if (response.statusCode === 403) {
                            localStorage.removeItem(Storage.ShopID);
                            return this.setState({
                                error: "You are forbidden to access this shops delivery information. Please navigate back to the shop dashboard.",
                            });
                        }

                        if (response.statusCode !== 200) {
                            return this.setState({
                                error: "Unable to retrieve the current delivery settings",
                            });
                        }

                        this.setState({
                            delivery: response.delivery,
                        });
                    })
                    .catch(() => {
                        return this.setState({
                            error: "Unable to retrieve the current delivery settings",
                        });
                    });
            })
            .catch(() => {
                return this.setState({
                    error: "Unable to retrieve the current delivery settings",
                });
            });
    }

    render() {
        const deliveryViewProps: DeliveryViewProps = {
            delivery: this.state.delivery,
            error: this.state.error,
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
        };

        if (this.state.delivery?.isIntegrated) {
            return <ViewDelivery
                isIntegratedDeliveryAvailable={true}
                isIntegratedDelivery={true}
                onChange={() => this.optOutFromIntegratedDelivery()}>
                <ViewIntegratedDelivery {...deliveryViewProps} />
            </ViewDelivery>
        }

        return <ViewDelivery
            isIntegratedDeliveryAvailable={this.state.delivery?.isIntegratedAvailable || false}
            isIntegratedDelivery={false}
            onChange={this.optInForIntegratedDelivery}>
            <ViewCustomDelivery {...deliveryViewProps} />
        </ViewDelivery>
    }
}

export interface ViewDeliveryProps {
    isIntegratedDelivery: boolean
    isIntegratedDeliveryAvailable: boolean
    onChange: () => void
    children: ReactNode
}

export const ViewDelivery: FunctionComponent<ViewDeliveryProps> = (props) => (
    <div>
        <Row>
            <Col>
                <h1 style={{ marginBottom: "2rem" }}>Delivery</h1>
            </Col>
        </Row>
        {props.children}
    </div>
)

export interface DeliveryViewProps extends RouteComponentProps {
    delivery?: DeliveryDto;
    error: string;
}

function marshalDates(dates: DeliveryDateDto[]): Map<Day, DeliveryDateDto> {
    const result: Map<Day, DeliveryDateDto> = new Map();
    dates.forEach((date) => {
        result.set(date.day, date);
    });
    return result;
}

export const ViewCustomDelivery: FunctionComponent<DeliveryViewProps> = (props) => (
    <div>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        <Row>
            <Col sm={12} md={6} style={{ marginBottom: "2rem" }}>
                <DeliveryRadius
                    radius={
                        props.delivery && props.delivery.radius
                            ? props.delivery.radius
                            : 0
                    }
                    auth={Auth}
                    updateDeliveryRadiusAPI={new UpdateDeliveryRadius()}
                    {...props}
                />
            </Col>
            <Col sm={12} md={6} style={{ marginBottom: "2rem" }}>
                <DeliveryPrice
                    price={
                        props.delivery && props.delivery.price
                            ? props.delivery.price
                            : 0
                    }
                    auth={Auth}
                    updateDeliveryPriceAPI={new UpdateDeliveryPrice()}
                    {...props}
                />
            </Col>
            <Col sm={12} md={6} style={{ marginBottom: "2rem" }}>
                <MinimumPrice
                    price={
                        props.delivery && props.delivery.minimumPrice
                            ? props.delivery.minimumPrice
                            : 0
                    }
                    auth={Auth}
                    updateMinimumPriceAPI={new UpdateMinimumPrice()}
                    {...props}
                />
            </Col>
        </Row>
        <Row >
            <Col style={{ marginBottom: "2rem" }}>
                <DeliveryDate
                    dates={
                        props.delivery && props.delivery.dates
                            ? marshalDates(props.delivery.dates)
                            : new Map()
                    }
                    auth={Auth}
                    updateDeliveryDateAPI={new UpdateDeliveryDate()}
                    {...props}
                />
            </Col>
        </Row>
    </div>
);

export const ViewIntegratedDelivery: FunctionComponent<DeliveryViewProps> = (props) => (
    <div>
        <Alert variant={"danger"} show={props.error !== ""}>
            {props.error}
        </Alert>
        <Row >
            <Col style={{ marginBottom: "2rem" }}>
                <Card>
                    <Card.Body>
                        <Card.Title>Integrated Delivery Selected</Card.Title>
                        <Card.Text style={{ marginTop: "2rem" }}>
                            <h6>What to Expect:</h6>
                            <ul>
                                <li>A dedicated courier will be assigned for the collection and delivery of your orders.</li>
                                <li>The courier will visit your shop during scheduled day and time range to pick up the orders.</li>
                                <li>Delivery to customers will be managed entirely by the courier.</li>
                            </ul>
                            <h6>Customer Payments:</h6>
                            <ul>
                                <li>Customers will pay the courier directly, and you won't incur any costs for the delivery service.</li>
                            </ul>
                            <h6>Your Role:</h6>
                            <ul>
                                <li>Ensure orders are ready for pickup during the scheduled time range.</li>
                                <li>Package items securely and label them with the <strong>Order ID</strong> and <strong>Address</strong>.</li>
                            </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
);
