import { Auth } from "aws-amplify";
import React, { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Card, ListGroup, Row, Col, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { AssignUserToShopRequestInput, AssignUserToShopRequestOutput, GetShopContactDetailsInput, GetShopContactDetailsOutput, IAssignUserShopRequest, IGetShopContactDetails, IRegisterShop, RegisterShopInput, RegisterShopOutput, ShopSummary } from "../../../client/core";
import { Color, Path, Storage } from "../../../env";
import { Button, variant } from "../../form/Button";
import {
    Image,
} from "react-bootstrap";

export interface RegisterShopState {
    isLoading: boolean;
    error: string;
    state: State;
    shop?: ShopSummary;
}

export interface RegisterShopProps extends RouteComponentProps {
    getShopContactDetails: (input: GetShopContactDetailsInput) => Promise<GetShopContactDetailsOutput | Error>
    assignUserToShopRequest: (input: AssignUserToShopRequestInput) => Promise<AssignUserToShopRequestOutput | Error>
    registerShop: (input: RegisterShopInput) => Promise<RegisterShopOutput>
    auth: typeof Auth;
}

export enum State {
    None,
    ShopConfirmed,
}

export default class RegisterShop extends Component<
    RegisterShopProps,
    RegisterShopState
> {
    constructor(props: RegisterShopProps) {
        super(props);
        this.state = {
            isLoading: true,
            state: State.None,
            error: "",
        };
    }

    componentDidMount(): void {
        const shopIdToCreate = localStorage.getItem(Storage.OpenShop)
        if (shopIdToCreate == null) {
            return this.setState({
                isLoading: false,
            })
        }

        this.props.getShopContactDetails({
            shopId: shopIdToCreate
        }).then(response => {
            if (response instanceof Error || !response.body) {
                return this.setState({
                    isLoading: false,
                })
            }

            this.setState({
                isLoading: false,
                shop: {
                    name: response.body.name,
                    email: response.body.email,
                    address: response.body.address,
                    phone: response.body.phone,
                    accountStatus: "",
                    actions: [],
                    active: false,
                    alias: "",
                    color: "",
                    credit: 0,
                    deliveryStatus: "",
                    logo: response.body.logo || "",
                }
            })
        })
    }

    handleSubmit = () => {
        this.props.auth
            .currentSession()
            .then((session) => {
                const token = session.getIdToken().getJwtToken();
                this.props.registerShop({
                    identityToken: token,
                    referral: localStorage.getItem(Storage.Referral) || undefined
                }).then((output) => {
                    if (output.statusCode === 403) {
                        return this.props.history.push(Path.Login);
                    }

                    if (output.statusCode === 201) {
                        localStorage.setItem(Storage.ShopID, output.shopID);
                        return this.props.history.push(Path.RegisterShopName);
                    }

                    this.setState({
                        error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                    });
                }).catch(() =>
                    this.setState({
                        error: "Oop, something unexpected went wrong during the registration. We will investigate why there was an error and get back to you.",
                    })
                );
            })
            .catch(() => {
                this.props.history.push(Path.Login);
            });
    };

    handleAssignUserToShop = () => {
        const shopIdToAssignUser = localStorage.getItem(Storage.OpenShop)
        if (shopIdToAssignUser == null) {
            return
        }

        this.setState({
            state: State.ShopConfirmed
        })

        this.props.auth.currentSession().then(session => {
            this.props.assignUserToShopRequest({
                identityToken: session.getIdToken().getJwtToken(),
                shopId: shopIdToAssignUser
            }).then(response => {
                if (response instanceof Error || response.statusCode !== 200) {
                    return this.setState({
                        state: State.None,
                        error: "We were unable to allocate you to this shop at this time. Please contact support@stumbled.online if this issue persists."
                    })
                }
            })
        }).catch(err => {
            localStorage.removeItem(Storage.IsLoggedIn)
            this.props.auth.signOut()
            this.props.history.push(Path.Login)
        })
    }

    render() {
        if (this.state.isLoading) {
            return <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" color={Color.Primary} /></div>
        }

        const props: RegisterShopFormProps = {
            error: this.state.error,
            shop: this.state.shop,
            state: this.state.state,
            onSubmit: this.handleSubmit,
            onAssignUserToShop: this.handleAssignUserToShop,
        };
        return <RegisterShopForm {...props} />;
    }
}

export interface RegisterShopFormProps {
    error: string;
    shop?: ShopSummary;
    state: State;
    onSubmit: () => void;
    onAssignUserToShop: () => void;
}

export const RegisterShopForm: FunctionComponent<RegisterShopFormProps> = (
    props
) => (
    <Form>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        {(props.shop) && (
            <>
                <Row>
                    <Col xs={12} style={{ margin: "auto" }}>
                        <Card>
                            <Card.Body>
                                <Card.Title style={{marginBottom: "1.5rem"}}>Is this your shop?</Card.Title>
                                <Row>
                                    <Col>
                                        <div>
                                            <div className={"elevate"} style={{ width: "250px", textAlign: "center", margin: "auto", border: "1px solid lightgrey" }}>
                                                <Image style={{ display: "block", padding: "1rem" }} width="100%" src={props.shop.logo || "https://via.placeholder.com/350x350.png?text=Logo"} rounded />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Name</div>
                                                    {props.shop?.name || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Address</div>
                                                    {props.shop?.address || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                    <Col>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Email</div>
                                                    {props.shop?.email || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ border: 0 }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Phone</div>
                                                    {props.shop?.phone || ""}
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "right" }}>
                                    <Col>
                                        {(props.state === State.None) && <Button
                                            variant={variant.Primary}
                                            name={"Yes, that's my shop"}
                                            onClick={() => props.onAssignUserToShop()}
                                        />}
                                        {(props.state === State.ShopConfirmed) && <span style={{ color: Color.Primary, fontWeight: 600 }}>Great! You should receive an email shortly to associate this user to the shop.</span>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {(props.state === State.None) && <Row>
                    <Col style={{ textAlign: "center", marginTop: "1rem" }}>
                        <h4 style={{ color: Color.Primary }}>Or</h4>
                    </Col>
                </Row>}
            </>
        )}
        {(props.state === State.None) && < Card style={{ marginTop: "1rem" }}>
            <Card.Body>
                <Card.Title>Register your shop</Card.Title>
                <Card.Text>If you are <strong style={{ color: Color.Primary }}>NOT</strong> registering to sell produce, and instead want to buy produce, you can download our mobile app from the App Store: <span style={{ color: Color.Primary, fontWeight: "bold" }}>Stumbled: Local Shops</span> or visit <a style={{ color: Color.Primary }} href={`https://shops.${process.env.REACT_APP_BASE_DOMAIN}`}>https://shops.{process.env.REACT_APP_BASE_DOMAIN}</a>. You will be able to login with the same credentials.</Card.Text>
                <Card.Text>If you wish to register your shop please click on the button below and continue with your journey.</Card.Text>
                <Row>
                    <Col style={{ textAlign: "right" }}>
                        <Button
                            variant={variant.Primary}
                            name={"Register"}
                            onClick={() => props.onSubmit()} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>}
    </Form >
);
